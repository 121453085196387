<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12">
            <list-table-data
              :table-columns="tableColumns"
              :table-data="items"
              :redirect-edit="redirectEdit"
              :redirect-show="redirectShow"
              :origen="catalogoName"
              :show-icon-new="true"
              :tipo-catalogo="catalogoType"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import ListTableData from '@/components/ListTableData.vue'
import { getlistado } from '@/utils/catalogos'

export default {
  components: {
    ListTableData,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      redirectEdit: 'catalogos-edit-gabinete', // no se cambia
      redirectShow: 'catalogos-edit-gabinete', // no se cambia
      catalogoName: 'Trabajo Centros de Mando P&M', // se cambia segun el nombre que sea el catalogo
      catalogoType: 19, // id se cambia segun el catalogo
      items: [],
      tableColumns: [
        {
          label: 'Nombre',
          field: 'nombre',
        },
        {
          label: 'Estado',
          field: 'active',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Acciones',
          field: 'acciones',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
    }
  },
  async beforeMount() {
    try {
      const user = JSON.parse(localStorage.getItem('userData'))

      this.items = await getlistado(this.catalogoType, this.catalogoName, true, user)
      if (this.items === null) this.items = []
    } catch (err) {
      console.error(`Error en cargar ${this.catalogoName}`, err)
      this.items = []
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
